import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import BlogItem from "../components/blog/BlogItem";
import Container from "../components/container";

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Blog">
      <Container verticalPadding={false} extraClasses="pv2 pv4-ns" maxWidth={1200}>
        <div className="cf ph2-ns">
          <div className="fl w-100 w-70-ns pa2">
            {data.allMarkdownRemark.edges.map(({ node }) => (
            <BlogItem
              id={node.id}
              title={node.frontmatter.title}
              author={node.frontmatter.author}
              date={node.frontmatter.date}
              excerpt={node.excerpt}
              timeToRead={node.timeToRead}
              slug={node.fields.slug}
              image={node.frontmatter.featuredImage.childImageSharp.fluid}
            />
          ))}
          </div>
          <div className="fl w-100 w-30-ns pa2">
            <ul className={"blog-sidebar"}>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <li id={node.id}>
                  <Link to={node.fields.slug}>
                    {node.frontmatter.title}
                  </Link>
                  <div className="mt3">{node.frontmatter.date}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default BlogPage;

export const query = graphql`
query BlogPageQuery {
  allMarkdownRemark(
    sort: {order: DESC, fields: [frontmatter___date]},
    filter: {fileAbsolutePath: {regex: "/src/pages/blog/*/"}}
  ) {
    totalCount
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMM YYYY")
          author
          featuredImage {
            childImageSharp{
              fluid(quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        excerpt
      }
    }
  }
}
`;
