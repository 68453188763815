import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";

const BlogItem = ({ id, slug, title, author, date, excerpt, image }) => (
  <div key={id} className="article-box mb4">
    <Link to={slug}>
      <Image fluid={image} className={"blog-item-image"}/>
    </Link>
    <Link to={slug} className="link f3 mv2 db default-grey">{title}</Link>
    <span className="f7 tracked ttu"><b className="pr2">{author}</b> {date}</span>
    <p className="f6 mv2">{excerpt}</p>
  </div>
);

export default BlogItem;
